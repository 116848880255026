import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import FormInput from '../../../Components/FormInput';
import PageTabHeader from '../../../Components/Page/PageTabHeader';
import PageTitle from '../../../Components/Page/PageTitle';
import APP_HELPERS_COMMON from '../../../Helpers/Common';
import APP_MODEL_KARYAWAN from '../../../Models/Karyawan/datakaryawan';
import ElementAlert from '../../../Components/Element/ElementAlert';
import ElementPreloaderForm from '../../../Components/Element/ElementPreloaderForm';
import ElementModal from '../../../Components/Element/ElementModal';

function Payroll() {
    /* ----------
    URL Params
    ------------------ */
        let navigate        = useNavigate();
        let urlAction       = useParams();
        let urlID           = urlAction.id;
    /* ----------
    Page Vars
    ------------------ */
        const dataID = APP_HELPERS_COMMON.dcrypt(urlID);
        const [pageTitle, setPageTitle] = useState("Payroll");
        const [pageID, setPageID] = useState(13);
        const [tabs, setTabs] = useState([]);
        const [activeTab, setActiveTab] = useState('');
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
        // -------------------
        const [alertOpen, setAlertOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState(false);
        const [alertStyle, setAlertStyle] = useState("");
        const [modalTitle, setModalTitle] = useState("");
        const [modalPosition, setModalPosition] = useState("right");
        const defaultOption = { value: '0', label: ' - Pilih -' };
        /* ----------
        Data Payroll
        -------------------- */
        const [inputID, setInputID] = useState('');
        const [inputNik, setInputNik] = useState('');
        const [inputNama, setInputNama] = useState('');
        const [inputNoBPJSKetenagakerjaan, setInputNoBPJSKetenagakerjaan] = useState('');
        const [inputTanggalBPJSKetenagakerjaan, setInputTanggalBPJSKetenagakerjaan] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputNoBPJSKesehatan, setInputNoBPJSKesehatan] = useState('');
        const [inputTanggalBPJSKesehatan, setInputTanggalBPJSKesehatan] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputTanggalAkhirBPJSKesehatan, setInputTanggalAkhirBPJSKesehatan] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputTanggalGajiTerakhir, setInputTanggalGajiTerakhir] = useState(APP_HELPERS_COMMON.getCurrentDate());
        const [inputBank, setInputBank] = useState(defaultOption);
        const [inputNoRekening, setInputNoRekening] = useState('');
        const [inputAtasNama, setInputAtasNama] = useState('');
        const [inputNoNPWP, setInputNoNPWP] = useState('');
        const [inputStatusPTKP, setInputStatusPTKP] = useState(defaultOption);
        const [inputMetodePajak, setInputMetodePajak] = useState(defaultOption);
        const [inputSelectKomponen, setInputSelectKomponen] = useState(defaultOption);
        const [inputSelectNominalKomponen, setInputSelectNominalKomponen] = useState(0);
        const [inputGajiPokok, setInputGajiPokok] = useState(0);
        const [inputGajiBPJS, setInputGajiBPJS] = useState(0);
        const [inputGajiJamsostek, setInputGajiJamsostek] = useState(0);
        const [inputGajiPensiun, setInputGajiPensiun] = useState(0);
        const [inputJumlahBPJSKeluarga, setInputJumlahBPJSKeluarga] = useState('');
        const [inputKomponenGaji, setInputKomponenGaji] = useState([]);
        const [inputStatusPembayaranBPJS, setInputStatusPembayaranBPJS] = useState(defaultOption);
        const [inputStatusPembayaranJHT, setInputStatusPembayaranJHT] = useState(defaultOption);
        const [inputStatusPembayaranPensiun, setInputStatusPembayaranPensiun] = useState(defaultOption);
        /* ----------
        Collection
        -------------------- */
        const [dataBank, setDataBank] = useState([]);
        const [dataStatusPTKP, setDataStatusPTKP] = useState([]);
        const [dataMetodePajak, setDataMetodePajak] = useState([]);
        const [dataKomponen, setDataKomponen] = useState([]);
        const [dataYaTidak, setDataYaTidak] = useState([]);
    /* ----------
    Page Data
    ------------------ */
        useEffect(async () => {
            let resKaryawan  = await APP_MODEL_KARYAWAN.getPayroll(dataID);
            /* ----------
            Data Payroll
            -------------------- */
            setInputID(resKaryawan.data.id);
            setInputNik(resKaryawan.data.nik);
            setInputNama(resKaryawan.data.nama_karyawan);
            setInputNoBPJSKetenagakerjaan(resKaryawan.data.no_bpjs_ketenagakerjaan);
            setInputTanggalBPJSKetenagakerjaan(resKaryawan.data.tanggal_bpjs_ketenagakerjaan);
            setInputTanggalGajiTerakhir(resKaryawan.data.tanggal_gaji_terakhir);
            setInputNoBPJSKesehatan(resKaryawan.data.no_bpjs_kesehatan);
            setInputTanggalBPJSKesehatan(resKaryawan.data.tanggal_bpjs_kesehatan);
            setInputTanggalAkhirBPJSKesehatan(resKaryawan.data.tanggal_akhir_bpjs_kesehatan);
            setInputBank({ value: resKaryawan.data.bank_id, label: resKaryawan.data.nama_bank });
            setInputNoRekening(resKaryawan.data.no_rekening_bank);
            setInputAtasNama(resKaryawan.data.atas_nama_rekening);
            setInputNoNPWP(resKaryawan.data.no_npwp);
            setInputStatusPTKP({ value: resKaryawan.data.status_ptkp_id, label: resKaryawan.data.ket_status_ptkp });
            setInputMetodePajak({ value: resKaryawan.data.metode_tax_id, label: resKaryawan.data.ket_metode_pajak });
            setInputGajiPokok(resKaryawan.data.gaji_pokok);
            setInputGajiBPJS(resKaryawan.data.gaji_bpjs);
            setInputGajiPensiun(resKaryawan.data.gaji_pensiun);
            setInputGajiJamsostek(resKaryawan.data.gaji_jamsostek);
            setInputKomponenGaji(resKaryawan.komponen_gaji);
            setInputJumlahBPJSKeluarga(resKaryawan.data.jumlah_bpjs_keluarga);
            setInputStatusPembayaranBPJS({ value: resKaryawan.data.status_pembayaran_bpjs, label: resKaryawan.data.ket_status_pembayaran_bpjs });
            setInputStatusPembayaranJHT({ value: resKaryawan.data.status_pembayaran_jht, label: resKaryawan.data.ket_status_pembayaran_jht });
            setInputStatusPembayaranPensiun({ value: resKaryawan.data.status_pembayaran_pensiun, label: resKaryawan.data.ket_status_pembayaran_pensiun });
            /* ----------
            Collection
            -------------------- */
            dataStatusPTKP.push(...resKaryawan.collections.status_ptkp);
            dataMetodePajak.push(...resKaryawan.collections.metode_pajak);
            dataBank.push(...resKaryawan.collections.bank);
            dataKomponen.push(...resKaryawan.collections.komponen);
            dataYaTidak.push(...resKaryawan.collections.ya_tidak);

            setIsLoading(false);
            
            return () => {
                setDataBank([]);
                setDataStatusPTKP([]);
                setDataMetodePajak([]);
                setDataKomponen([]);
                setDataYaTidak([]);
            };
        }, []);

    /* ----------
    Component Render
    ------------------ */
        const renderTabs = () => {
            if(tabs.length > 0) {
                return <PageTabHeader tabs={tabs} active={activeTab} anotherDisable={false} handleActiveTab={(data) => handleActiveTab(data)} />
            }
        }
        const renderSubmit = () => {
            return <div className="row">
                        <div className="col-md-12" style={{textAlign: 'left'}}>
                            <hr />
                            <FormInput type="submit" label="Simpan" loading={isLoadingSubmit} handleSubmit={handleSubmit} />
                        </div>
                    </div>
        }
    /* ----------
    Method
    ------------------ */
        function handleActiveTab(data) {
            setActiveTab(data)
        }

        function handleUpdateKomponen(value, item, index) {
            const updateItem = {
                ...item,
                nominal: value
            }

            const newKomponen = [...inputKomponenGaji];
            newKomponen[index] = updateItem;
            setInputKomponenGaji(newKomponen);
        }

        function handleSubmitKomponen() {
            if((inputSelectKomponen.value > 0) && (inputSelectNominalKomponen > 0)) {
                const komponenIndex = inputKomponenGaji.findIndex(function(item) {
                    return item.komponen_payroll_id == inputSelectKomponen.value;
                });
                
                if(komponenIndex == -1) {
                    setInputKomponenGaji([...inputKomponenGaji, {
                        karyawan_id: dataID,
                        karyawan_payroll_id: 0,
                        komponen_payroll_id: inputSelectKomponen.value,
                        nama_komponen: inputSelectKomponen.label,
                        nominal: inputSelectNominalKomponen,
                    }]);
                } else {
                    inputKomponenGaji[komponenIndex].nominal = inputSelectNominalKomponen;
                }
                
                setInputSelectNominalKomponen(0);
                setInputSelectKomponen(defaultOption);
            }
        }

        function handleTambahKomponen(val, title) {
            setModalPosition(val)
            setModalTitle(title)
        }

        const handleSubmit = async (e) => {
            e.preventDefault();
            
            setIsLoadingSubmit(true);

            const formSubmit = {
                karyawan_id: inputID,
                nama_karyawan: inputNama,
                nik: inputNik,
                no_bpjs_ketenagakerjaan: inputNoBPJSKetenagakerjaan,
                tanggal_bpjs_ketenagakerjaan: inputTanggalBPJSKetenagakerjaan,
                no_bpjs_kesehatan: inputNoBPJSKesehatan,
                tanggal_bpjs_kesehatan: inputTanggalBPJSKesehatan,
                tanggal_akhir_bpjs_kesehatan: inputTanggalAkhirBPJSKesehatan,
                bank_id: inputBank.value,
                no_rekening_bank: inputNoRekening,
                atas_nama_rekening: inputAtasNama,
                no_npwp: inputNoNPWP,
                status_ptkp_id: inputStatusPTKP.value,
                metode_tax_id: 3, //inputMetodePajak.value,
                gaji_pokok: inputGajiPokok,
                gaji_bpjs: inputGajiBPJS,
                gaji_pensiun: inputGajiPensiun,
                gaji_jamsostek: inputGajiJamsostek,
                komponen_gaji: inputKomponenGaji,

                status_pembayaran_bpjs: inputStatusPembayaranBPJS.value,
                status_pembayaran_jht: inputStatusPembayaranJHT.value,
                status_pembayaran_pensiun: inputStatusPembayaranPensiun.value,
                tanggal_gaji_terakhir: inputTanggalGajiTerakhir,
                jumlah_bpjs_keluarga: inputJumlahBPJSKeluarga,
            }
            console.log(formSubmit)
            let res = await APP_MODEL_KARYAWAN.updatePayroll(formSubmit);

            if(res.status == '100') {
                navigate("/datakaryawan/index/" + APP_HELPERS_COMMON.crypt(pageID.toString()));
            } else {
                setIsLoadingSubmit(false);
                setAlertMessage(res.message);
                setAlertOpen(true);
                setAlertStyle("error");
            }
        }
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle pageTitle={pageTitle} />
                <ElementAlert message={alertMessage} open={alertOpen} handleClose={() => setAlertOpen(false)} style={alertStyle} /> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        {renderTabs()}
                        <div className="tab-content">
                            <div className="tab-pane active" id="dataPribadi" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{pageTitle}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            {
                                                (isLoading) ?
                                                    <ElementPreloaderForm />
                                                :
                                                    <>
                                                        <div className="row">
                                                            <FormInput type="text" label="N I K" size="2" readonly="readonly" mandatory={true} val={inputNik} onChangeValue={(event) => setInputNik(event.target.value)}/>
                                                            <FormInput type="text" label="Nama Lengkap" size="2" readonly="readonly" mandatory={true} val={inputNama} onChangeValue={(event) => setInputNama(event.target.value)}/>
                                                            <div className="col-md-12" style={{marginTop: 20}}>
                                                                <h4 className="card-title">Informasi BPJS</h4>
                                                                <hr />
                                                            </div>
                                                                <FormInput type="text" label="No BPJS Ketenagakerjaan" size="2" mandatory={true} val={inputNoBPJSKetenagakerjaan} onChangeValue={(event) => setInputNoBPJSKetenagakerjaan(event.target.value)}/>
                                                                <FormInput type="date-picker" label="Tanggal BPJS Ketenagakerjaan" size="2" mandatory={true} val={inputTanggalBPJSKetenagakerjaan} onChangeValue={(event) => setInputTanggalBPJSKetenagakerjaan(event.target.value)}/>
                                                                <FormInput type="text" label="No BPJS Kesehatan" size="2" mandatory={true} val={inputNoBPJSKesehatan} onChangeValue={(event) => setInputNoBPJSKesehatan(event.target.value)}/>
                                                                <FormInput type="date-picker" label="Tanggal BPJS Kesehatan" size="2" mandatory={true} val={inputTanggalBPJSKesehatan} onChangeValue={(event) => setInputTanggalBPJSKesehatan(event.target.value)}/>
                                                                <FormInput type="date-picker" label="Tanggal Akhir BPJS Kesehatan" size="2" mandatory={true} val={inputTanggalAkhirBPJSKesehatan} onChangeValue={(event) => setInputTanggalAkhirBPJSKesehatan(event.target.value)}/>

                                                                <FormInput type="select" label="BPJS Dibayarkan ?" size="2" mandatory={true} val={inputStatusPembayaranBPJS} option={dataYaTidak} defaultSelect={inputStatusPembayaranBPJS} onChangeValue={(event) => setInputStatusPembayaranBPJS(event)}/>
                                                                <FormInput type="select" label="JHT Dibayarkan ?" size="2" mandatory={true} val={inputStatusPembayaranJHT} option={dataYaTidak} defaultSelect={inputStatusPembayaranJHT} onChangeValue={(event) => setInputStatusPembayaranJHT(event)}/>
                                                                <FormInput type="select" label="Pensiun Dibayarkan ?" size="2" mandatory={true} val={inputStatusPembayaranPensiun} option={dataYaTidak} defaultSelect={inputStatusPembayaranPensiun} onChangeValue={(event) => setInputStatusPembayaranPensiun(event)}/>
                                                                <FormInput type="text" label="Jumlah BPJS Tambahan (Keluarga)" size="1" mandatory={true} val={inputJumlahBPJSKeluarga} onChangeValue={(event) => setInputJumlahBPJSKeluarga(event.target.value)}/>
                                                            <div className="col-md-12" style={{marginTop: 20}}>
                                                                <h4 className="card-title">Informasi Rekening</h4>
                                                                <hr />
                                                            </div>
                                                                <FormInput type="select" label="Bank" size="2" mandatory={true} val={inputBank} option={dataBank} defaultSelect={inputBank} onChangeValue={(event) => setInputBank(event)}/>
                                                                <FormInput type="text" label="No Rekening" size="2" mandatory={true} val={inputNoRekening} onChangeValue={(event) => setInputNoRekening(event.target.value)}/>
                                                                <FormInput type="text" label="Atas Nama Rekening" size="2" mandatory={true} val={inputAtasNama} onChangeValue={(event) => setInputAtasNama(event.target.value)}/>
                                                            <div className="col-md-12" style={{marginTop: 20}}>
                                                                <h4 className="card-title">Informasi Pajak</h4>
                                                                <hr />
                                                            </div>
                                                                <FormInput type="text" label="No NPWP" size="2" mandatory={true} val={inputNoNPWP} onChangeValue={(event) => setInputNoNPWP(event.target.value)}/>
                                                                <FormInput type="select" label="Status PTKP" size="2" mandatory={true} val={inputStatusPTKP} option={dataStatusPTKP} defaultSelect={inputStatusPTKP} onChangeValue={(event) => setInputStatusPTKP(event)}/>
                                                                {/* <FormInput type="select" label="Metode Pajak" size="2" mandatory={true} val={inputMetodePajak} option={dataMetodePajak} defaultSelect={inputMetodePajak} onChangeValue={(event) => setInputMetodePajak(event)}/> */}
                                                            <div className="col-md-12" style={{marginTop: 20}}>
                                                                <h4 className="card-title">Informasi Payroll</h4>
                                                                <hr />
                                                            </div>
                                                                <FormInput type="currency" label="Gaji Pokok" size="2" mandatory={true} val={inputGajiPokok} onChangeValue={(value) => setInputGajiPokok(value)}/>
                                                                <FormInput type="currency" label="Gaji BPJS" size="2" mandatory={true} val={inputGajiBPJS} onChangeValue={(value) => setInputGajiBPJS(value)}/>
                                                                <FormInput type="currency" label="Gaji Jamsostek" size="2" mandatory={true} val={inputGajiJamsostek} onChangeValue={(value) => setInputGajiJamsostek(value)}/>
                                                                <FormInput type="currency" label="Gaji Pensiun" size="2" mandatory={true} readonly="readonly" val={inputGajiPensiun} onChangeValue={(value) => setInputGajiPensiun(value)}/>
                                                            <div className="col-md-12" style={{marginTop: 20}}>
                                                                <h4 className="card-title">Informasi Penggajian</h4>
                                                                <hr />
                                                            </div>
                                                            <FormInput type="date-picker" label="Tanggal Gaji Terkahir" size="2" mandatory={true} val={inputTanggalGajiTerakhir} onChangeValue={(event) => setInputTanggalGajiTerakhir(event.target.value)}/>
                                                            {/* <div className="col-md-12" style={{marginTop: 20}}>
                                                                <h4 className="card-title">Komponen</h4>
                                                                <hr />
                                                            </div>
                                                                {
                                                                    inputKomponenGaji.map(function(item, index) {
                                                                        return (
                                                                            <>
                                                                                <FormInput type="currency" label={item.nama_komponen} size="2" mandatory={true} val={item.nominal} onChangeValue={(value) => handleUpdateKomponen(value, item, index)} />
                                                                                <FormInput type="empty-space" />
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            <div className="row">
                                                                <div className="col-md-12" style={{textAlign: 'left', marginTop: -20}}>
                                                                    <FormInput type="button-modal" label="Tambah Komponen Gaji" position="right" handleSetPosition={(val) => handleTambahKomponen(val, 'Komponen Gaji')} />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        {renderSubmit()}
                                                    </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ElementModal position={modalPosition} title={modalTitle}>
                <FormInput type="select" label="Komponen" size="1" mandatory={true} val={inputSelectKomponen} option={dataKomponen} defaultSelect={inputSelectKomponen} onChangeValue={(event) => setInputSelectKomponen(event)}/>
                <FormInput type="currency" label="Nominal" size="1" mandatory={true} val={inputSelectNominalKomponen} onChangeValue={(value) => setInputSelectNominalKomponen(value)}/>
                <FormInput type="button-dismiss" label="Simpan" handleSubmit={handleSubmitKomponen} />
            </ElementModal>
        </div>
    )
}

export default Payroll;