import React, { useEffect, useState } from 'react';
import axios from 'axios';
import APP_CONFIG from '../../Constants/App';
import APP_STORAGE from '../../Storage';
import { useNavigate } from "react-router-dom";

function Auth() {
    let navigate = useNavigate();

    const baseUrl = (APP_CONFIG.isProduction) ? APP_CONFIG.EndPointProduction : APP_CONFIG.EndPointStaging;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [userValue, setUserValue] = useState('');
    const [passValue, setPassValue] = useState(''); //Rahasia123!!

    const handleLogin = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setIsError(false);

        axios.post(baseUrl + '/api/auth/login', {
            name: userValue, 
            password: passValue 
        })
        .then((response) => {
            if(response.data.status == '100') {
                APP_STORAGE.clearLocalStorage();
                APP_STORAGE.saveCredential(response.data);
                
                navigate("/");
                
                window.location.reload();
            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            setIsLoading(false);
            setIsError(true);
        });
    }

    return (
        <>
            <div className="auth-page">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <div className="col-xxl-3 col-lg-4 col-md-5">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <a href="index.html" className="d-block auth-logo">
                                                <img src="assets/images/logo-sm.svg" alt="" height="28"/> <span className="logo-txt">Mitra Payroll</span>
                                            </a>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Welcome Back !</h5>
                                                <p className="text-muted mt-2">Sign in to continue to Mitra Payroll.</p>
                                            </div>
                                            <form className="mt-4 pt-2" action="index.html">
                                                <div className="mb-3">
                                                    <label className="form-label">Username</label>
                                                    <input type="text" className="form-control" id="username" placeholder="Enter username" value={userValue} onChange={(e) => setUserValue(e.target.value)}/>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <label className="form-label">Password</label>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="input-group auth-pass-inputgroup">
                                                        <input type="password" className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" value={passValue} onChange={(e) => setPassValue(e.target.value)}/>
                                                        <button className="btn btn-light shadow-none ms-0" type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                                                    </div>
                                                </div>
                                                {
                                                    (isError) && <div class="alert alert-danger" style={{textAlign: 'center'}} role="alert">User / Password salah !!!</div>
                                                }
                                                <div className="mb-3">
                                                    {
                                                        (!isLoading) ?
                                                            <button className="btn btn-primary w-100 waves-effect waves-light" type="submit" onClick={ handleLogin }>Log In</button>
                                                        :
                                                            <button type="submit" className="btn btn-primary w-md w-100" disabled>
                                                                <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                                            </button>        
                                                    }
                                                </div>
                                                <div className="mb-3">
                                                    
                                                </div>
                                            </form>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">© <script>document.write(new Date().getFullYear())</script> Mitra Payroll  <i className="mdi mdi-heart text-danger"></i> by Mitrafin (2022)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-9 col-lg-8 col-md-7">
                            <div className="auth-bg pt-md-5 p-4 d-flex">
                                <div className="bg-overlay bg-primary"></div>
                                <ul className="bg-bubbles">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-xl-7">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Auth;